import React from 'react';
import ReactDOM from 'react-dom';

import ContactPage from 'pages/ContactPage/ContactPage';

const contactPage = document.getElementById('contact-page') as HTMLElement;

if (contactPage) {
  ReactDOM.render(<ContactPage />, contactPage);
}
