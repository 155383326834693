import React, { useState } from 'react';
import { ContactForm, ContactInfo } from './components';
import { Alert } from 'components/UI/Alert';

const ContactPage = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  return (
    <main className='contact'>
      <div className='contact__alert-wrapper'>
        {showAlert && (
          <div className='contact__alert-container'>
            <Alert variant='info' isClosable={false} className='alert--text-centered'>
              Your message was successfully sent!
            </Alert>
          </div>
        )}
      </div>
      <section className='contact__section'>
        <div className='contact__wrapper'>
          <h1 className='contact__title'>Contact us</h1>
          <p className='contact__text'>
            Have a question or need help? Tell us about it, and we'll get back to you within one
            business day.
          </p>
          <ContactForm setShowAlert={setShowAlert} />
        </div>
        <ContactInfo />
      </section>
    </main>
  );
};

export default ContactPage;
