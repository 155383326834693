import React from 'react';

const ContactInfo = () => {
  return (
    <article className='contact__info'>
      <h2 className='contact__info-title'>Have a question?</h2>
      <a href='https://www.seditionart.com/help-centre' className='contact__link'>
        Visit our Help Centre
      </a>
      <h2 className='contact__info-title'>Want to contact us directly?</h2>
      <a
        href='mailto:support@seditionart.com'
        className='contact__link contact__link--extra-margin'
      >
        support@seditionart.com
      </a>
    </article>
  );
};

export default ContactInfo;
