import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { request } from 'utils/request';
import { CTA, Dropdown } from '.';

const contactURL = '/contact';

type Params = {
  name: string;
  email: string;
  message: string;
  inquiry: string;
};

type Props = {
  setShowAlert: (showAlert: boolean) => void;
};

const ContactForm = ({ setShowAlert }: Props) => {
  const options = ['General Questions', 'Artist Inquiries', 'Sedition for Business'];

  const [selected, setSelected] = useState<string>('General Questions');
  const [contactObj, setContactObj] = useState({
    inquiry: selected,
    name: '',
    email: '',
    message: '',
  });

  const [error, setError] = useState({
    name: false,
    email: false,
    message: false,
  });

  const sendMessage = async (params: Params) => {
    try {
      const res = await request.post(contactURL, {
        params,
      });
      if (res.data.success) return true;
      return false;
    } catch (err) {
      console.log('sendMessage error', err);
    }
  };

  const resetForm = (): void => {
    setContactObj({
      inquiry: 'General Questions',
      name: '',
      email: '',
      message: '',
    });
    setSelected('General Questions');
  };

  const formValidation = (contactObj: Params) => {
    const { name, email, message } = contactObj;

    const emailError = !email || !email.includes('@') || !email.includes('.');
    setError({
      name: !name ? true : false,
      email: emailError,
      message: !message ? true : false,
    });
    if (!name || emailError || !message) return { error: true };
    return { error: false };
  };

  const formSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { error } = formValidation(contactObj);
    if (error) return;

    const res = await sendMessage(contactObj);

    if (res) {
      resetForm();
      // delay animation by 300ms
      setTimeout(() => {
        setShowAlert(true);
      }, 300);

      // remove notification from DOM after 5s
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  useEffect(() => {
    setContactObj({ ...contactObj, inquiry: selected });
  }, [selected]);

  return (
    <form
      noValidate
      autoComplete='off'
      onSubmit={(e) => formSubmitHandler(e)}
      className={`contact__form ${error.name ? 'contact__form--name-error' : ''} ${
        error.email ? 'contact__form--email-error' : ''
      }`}
    >
      <label htmlFor='inquiry' className='contact__label'>
        Your inquiry
      </label>
      <Dropdown selected={selected} setSelected={setSelected} options={options} />
      <label htmlFor='name' className='contact__label'>
        Your name
      </label>
      <input
        autoComplete='on'
        type='text'
        name='name'
        id='name'
        placeholder='Your name'
        className={`contact__input`}
        value={contactObj.name}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setContactObj({ ...contactObj, name: e.target.value })
        }
      />
      {error.name && <p className={`contact__error`}>Please enter your name</p>}
      <label htmlFor='email' className='contact__label'>
        Email
      </label>
      <input
        autoComplete='on'
        type='email'
        name='email'
        formNoValidate
        id='email'
        placeholder='you@company.com'
        className={`contact__input`}
        value={contactObj.email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setContactObj({ ...contactObj, email: e.target.value })
        }
      />
      {error.email && <p className='contact__error'>Your email format seems to be incorrect</p>}
      <label htmlFor='message' className='contact__label'>
        Message
      </label>
      <textarea
        name='message'
        id='message'
        placeholder='Leave us a message...'
        className={`contact__textarea ${error.message ? 'contact__textarea--error' : ''}`}
        value={contactObj.message}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setContactObj({ ...contactObj, message: e.target.value })
        }
      />
      {error.message && <p className='contact__error'>Please enter a message</p>}
      <CTA className='btn btn--border-dark btn--responsive' label='SEND MESSAGE' />
    </form>
  );
};

export default ContactForm;
