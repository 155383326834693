import React from 'react';

type Props = {
  label: string;
  className: string;
};

const CTA = (props: Props) => {
  return <button className={props.className}>{props.label}</button>;
};

export default CTA;
